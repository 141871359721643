:root {
  --content-column-default-width: 650px;
}

.content-column-default {
  margin-right: auto;
  margin-left: auto;
  max-width: var(--content-column-default-width);
  padding: 0 20px;
}
