.mega-title {
  text-align: left;
  color: var(--text-colour-default);
  font-size: 38px;
  line-height: 1.05em;
  font-weight: 700;
}

@media (min-width: 600px) {
  .mega-title {
    font-size: 52px;
  }
}
